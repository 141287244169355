<template>
  <div id="app">
    <div class="app-wrapper" :class="pretty ? 'prettyApp' : ''">
      <div class="top-bar" v-if="pretty">
        <div class="logo-wrapper">
          <img class="logo" src="./assets/hometownheroes.png"/>
        </div>
        <div class="title-wrappers">
          <div class="title-wrapper">{{ title }}</div>
          <div class="subtitle-wrapper">{{ subtitle }} Lineup</div>
        </div>        
      </div>
      <div v-if="!pretty" class="options">
        <label>Team A vs Team B</label>
        <input placeholder="Hometown Heroes vs Meatball Heroes" v-model="title"/>
        <label>Date</label>
        <input placeholder="May 2nd, 2023" v-model="subtitle"/>
      </div>
      
      <div class="lines">
        <h2>Forwards</h2>
        <CustomLine
          v-for="(line, index) in forwardLines"
          :pretty="pretty"
          :key="index + 'forward'"
          :line="line"
          :lineIndex="index"
          :positions="forwardPositions"
          @updateLine="updateLine('forward', $event)"        
        />
        <div class="pill" v-show="!pretty">
          <button @click="removeForwardLine" class="minus">-</button>
          <button @click="addForwardLine" class="plus">+</button>
        </div>

        <h2>Defenders</h2>
        <CustomLine
          v-for="(line, index) in defenderLines"
          :pretty="pretty"
          :key="index + 'defender'"
          :line="line"
          :lineIndex="index"
          :positions="defenderPositions"
          @updateLine="updateLine('defender', $event)"        
        />

        <div class="pill" v-show="!pretty">
          <button @click="removeDefenderLine" class="minus">-</button>
          <button @click="addDefenderLine" class="plus">+</button>
        </div>

        <h2>Goalie</h2>
        <CustomLine
          v-for="(line, index) in goalieLines"
          :pretty="pretty"
          :key="index + 'goalie'"
          :line="line"
          :lineIndex="index"
          :positions="goaliePositions"
          @updateLine="updateLine('goalie', $event)"        
        />
      </div>
      <button v-if="!pretty" @click="takeScreenshot">Export Image</button>
    </div>
    
    <!-- {{  forwardLines }}
    {{ defenderLines }}
    {{  goalieLines }} -->
  </div>
</template>

<script>
import CustomLine from "./components/Line.vue";
import html2canvas from 'html2canvas';

export default {
  name: "App",
  components: {
    CustomLine,
  },
  data() {
    return {
      title: "",
      subtitle: "",
      forwardLines: [ { "LW": "Cestaro", "C": "Kopp", "RW": "Lucas" }, { "LW": "Schembari", "C": "Salem", "RW": "Haughney" }, { "LW": "Martino", "C": "Jaworski", "RW": "M. Reis" },],
      defenderLines: [ { "LD": "Jadatz", "RD": "Fagan" }, { "LD": "Rizzotti", "RD": "Becker" }],
      forwardPositions: ["LW", "C", "RW"],
      defenderPositions: ["LD", "RD"],
      goaliePositions: ["G"],
      goalieLines: [{"G": "Faithfull"}],
      timer: 1,
      pretty: false
    };
  },
  mounted() {
    this.title = localStorage.getItem('title')
    this.subtitle = localStorage.getItem('subtitle')
    this.forwardLines = JSON.parse(localStorage.getItem('forwardLines')) || this.forwardLines
    this.defenderLines = JSON.parse(localStorage.getItem('defenderLines')) || this.defenderLines
    this.goalieLines = JSON.parse(localStorage.getItem('goalieLines')) || this.goalieLines
  },
  watch: {
    'title': function(newValue) {
      this.save('title', newValue)
    },
    'subtitle': function(newValue) {
      this.save('subtitle', newValue)
    },
    // 'forwardLines': function(newValue) {
    //   this.save('forwardLines', newValue)
    // },
    'forwardLines': {
      handler: function(newVal) {
        console.log(newVal)
        this.save('forwardLines', JSON.stringify(newVal))
      },
      deep: true
    },
    'defenderLines': {
      handler: function(newVal) {
        console.log(newVal)
        this.save('defenderLines', JSON.stringify(newVal))
      },
      deep: true
    },
    'goalieLines': {
      handler: function(newVal) {
        console.log(newVal)
        this.save('goalieLines', JSON.stringify(newVal))
      },
      deep: true
    },
  },
  methods: {
    save: function(item, newValue) {
      localStorage.setItem(item, newValue)
    },
    debouncedSave: function(newValue) {
      const clone = this
      console.log('SAVED')
      clone.save(newValue);
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(function() {
        
        
      }, 2000)
    },
    takeScreenshot() {
      const clone = this;
      this.pretty = true
      setTimeout(() => {
        html2canvas(document.querySelector('.prettyApp')).then(function(canvas) {          
          canvas.toBlob((blob) => window.open(URL.createObjectURL(blob), '_blank'))  
        });
        clone.pretty = false
      }, 2000);
      
    },
    addForwardLine() {
      this.forwardLines.push({ LW: "", C: "", RW: "" });
    },
    removeForwardLine() {
      this.forwardLines.pop();
    },
    addDefenderLine() {
      this.defenderLines.push({ LD: "", RD: "" });
    },
    removeDefenderLine() {
      this.defenderLines.pop();
    },
    updateLine(type, { lineIndex, position, value }) {
      if (type === "forward") {
        this.forwardLines[lineIndex][position] = value;
      } else if (type === "defender") {
        this.defenderLines[lineIndex][position] = value;
      } else {
        this.goalieLines[lineIndex][position] = value;
      }
    },
    removeLine(type, lineIndex) {
      if (type === "forward") {
        this.forwardLines.splice(lineIndex, 1);
      } else if (type === "defender") {
        this.defenderLines.splice(lineIndex, 1);
      }
    }
  },
};
</script>

<style>
body {
  margin: 0;

}
#app {
  width: 1000px;
  height: 1000px;
}
.app-wrapper {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  text-align: center;
  color: #1f1f1f;
  position: absolute;
  font-family: 'Dongle', sans-serif;
  font-size: 250%;
  line-height: 58%;

}

.prettyApp {
  display: flex;
  flex-direction: column;
  width: 1000px;
  height: 1000px;
  justify-content: center;    
  background: url('./assets/rinki.png');
  background-size: cover;
}
.lines {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pill {
  display: inline-block;
  position: relative;
  height: 30px;
  width: 60px;
  border-radius: 15px;
  background-color: #ccc;
}

.minus,
.plus {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;
  height: 100%;
  border: none;
  background-color: transparent;
  font-size: 20px;
  line-height: 1;
  text-align: center;
}

.minus {
  left: 0;
}

.plus {
  right: 0;
}
h1 {
  margin: 0;
  font-family: 'Bebas Neue', cursive;
  font-size: 150%;
}
h2 {
  margin-bottom: 10px;
  margin-top: 30px;
  font-size: 120%;
  font-family: 'Bebas Neue', cursive;
}

input {
  text-align: center;
}

.logo-wrapper {
    width: 100px;
    position: absolute;
    top: 0;
    margin: 0.1em;
    background: white;
    border-radius: 62px;
    padding: 10px;    
    filter: blur(0.04px);
}
.logo {
  width: 100%;
}
.title-wrappers {
    line-height: 100%;
    text-align: left;
    padding-left: 4em;
}
.title-wrapper {
  font-size: 1.3em;
  font-family: 'Bebas Neue', cursive;
}
.subtitle-wrapper {
  font-family: 'Bebas Neue', cursive;
}
.top-bar {
    background: #202020;
    padding: 0.7em 0;
    width: 100%;
    color: white;
    flex-direction: column;
    display: flex;
    top: 0;
    position: absolute;
}

.options {
    display: flex;
    padding: 1em;
    flex-direction: column;
}
</style>
