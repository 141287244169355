<template>
  <div class="line">
    <span v-show="positions[0] !== 'G'" class="line-number">Line {{ lineIndex + 1 }}</span>
    <div class="positions-wrapper">
      <div
        class="position"
        :class="pretty ? 'prettyPosition' : ''"
        v-for="(position, index) in positions"
        :key="index + position"
      >
        
        <label class="posLabel" v-show="positions[0] !== 'G'"><b>{{ position }}</b></label>
        <input v-show="!pretty"
          type="text"
          :placeholder="position"
          :value="line[position]"
          @input="updatePlayer($event.target.value, position)"
        />
        <span v-show="pretty">
          {{line[position]}}
        </span>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "CustomLine",
  props: {
    pretty: Boolean,
    line: Object,
    positions: Array,
    lineIndex: {
      type: Number,
      default: null,
    },
  },
  methods: {
    updatePlayer(value, position) {
      this.$emit("updateLine", {
        lineIndex: this.lineIndex,
        position,
        value,
      });
    }
  },
};
</script>

<style scoped>
  .posLabel {
    color: black;
  }
  .positions-wrapper {
    display: flex;
    
    margin-bottom: 10px; 
    justify-content: space-around;
 
  }

  .position {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    
    align-items: center;
  }

  .prettyPosition {
    min-width: 250px;
  }

  .position.prettyPosition {
    background: #0072ff;
    padding: 3px;
    min-height: 45px;
    display: flex;
    color: white;
    border-radius: 32px;
    justify-content: center;
}

  input {
    width: 100px;
    padding: 5px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  span.line-number {
    color: grey;
  }
</style>
   
